@import "dyl-components/config.scss";

.ui.grid {
    .column.ScriptsGrid {
        margin-left: 15px;
    }
}

.ui.segment.TableWithHeader__header {
    background-color: $primary-table-6;
}
