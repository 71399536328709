@import "dyl-components/config.scss";

.WebFormsPanel {
    .ui.segments.TableWithHeader {
      border-style: none;
      box-shadow: none;
  
      .ui.segment.TableWithHeader__header {
        background-color: $white;
        border-radius: 0px;
        padding: 0px 0px 20px;
  
        .WebFormsToolbar {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
  
          .SearchBar__SearchInput .ui.input {
            width: 450px;
          }
  
          &__NewWebFormButton {
            width: 180px;
            margin-right: 0px;
            color: $white;
            background-color: $primary-table-6;
          }
        }
      }
  
      .ui.segment.TableWithHeader__table {
        border-style: none;
        border-radius: 0px;
  
        .ui.segments {
          border-style: none;
          border-radius: 0px;
          box-shadow: none;
  
          .ui.segment {
            border-style: none;
            border-radius: 0px;
  
            .ui.striped.table { 
              thead tr.Table__row th {
                background-color: $primary-table-6;
                color: $white;
              }
            }
          }
  
          .ui.segment.right {
            padding-left: 0px;
            padding-right: 0px;
          }
        }
      }
    }
  }
  
  .ui.input > input {
    width: 15em;
  }
  