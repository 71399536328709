@import '~dyl-components/config.scss';


.ui.segment.Webform__controls {
  border-top-width: 7px;
  border-top-style: solid;
  border-top-color: $primary1-governor-bay;
  padding-left: 0;
  padding-right: 0;

  .ui.menu.Webform__controls-menu {
    justify-content: center;
  }
}

.ui.segment.Webform--empty {
  padding: 5em;
}

.ui.menu.Webform__menu {
  margin-bottom: 0;
  background: linear-gradient(
      rgba($primary1-governor-bay, 0.1),
      rgba($primary1-governor-bay, 0.1)
    ),
    linear-gradient($white, $white);
  z-index: 100;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 6px 0px;
}

.Webform__field-options {
  max-height: calc(100vh - 33em);
  overflow-y: scroll;
  overflow-x: hidden;
  text-align: -webkit-center;

  .Webform__field-option {
    margin: 1em 1.5em;
  }

  .ui.segment.Webform__field-category {
    margin: 1em 1.5em;
  }
}

.Webform__field-search {
  margin: 1em 1.5em;
  margin-right: 2em;
}

.ui.menu.Webform__field-categories {
  margin-left: 1.5em;
  margin-right: 2em;
  width: unset !important;

  .item {
    justify-content: center;

    &.active {
      background-color: $primary;
      color: $white !important;

      &:hover {
        background-color: darken($color: $primary, $amount: 20) !important;
        color: $white !important;
      }
    }

    &:hover {
      background-color: unset;
      color: $primary !important;
    }
  }
}

.Webform__details {
  padding-left: 4em;
}

.Webform__form-options {
  height: 61vh;
  padding: 1.5em;
}

.ui.image.WebformPreview__logo {
  height: 50px;
  width: 150px;
  object-fit: contain;
  object-position: 0% 50%;
}

.WebForm__ShareOptionField {
  margin-bottom: 25px;

  .ShareOptionField__Label {
    margin-bottom: 5px; 
    font-weight: bold;

    .ShareOptionField__Icon {
      background-color: rgba($secondary-persian-green, 0.1);
      box-shadow: none;
    }
  }

  .ShareOptionField__Input {
    width: 31em; 
    margin-right: 2em;

    input {
      background-color: $disabled;
    }
  }
}
