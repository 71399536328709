@import "~dyl-components/config.scss";

.ui.segment.HeaderCompSegment {
    border-style: none;
    border-radius: 0px;
}

.UserTeamsPanel {
    background-color: white;

    .TeamOptions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        .SearchBar__SearchInput > div > input {
            width: 450px;
        }
        &__SearchInput {
            width: 450px;
        }

        .ui.primary.button.Button {
            width: 180px;
        }
    }

    .TeamsTable {
        margin-top: 25px;

        thead tr {
            background-color: $primary-table-6;

            th {
                background-color: $primary-table-6;
                padding: 16px;
                color: white;
            }
        }

        tbody tr > td {
            padding: 25px 20px;
        }

        &__TeamMembersCell {
            padding: 12px 0px 12px 0px !important;

            .TeamMembersFlex {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                .TeamMember {
                    margin: 11px 0px 11px 20px;
                    width: 210px !important;

                    &--inactive {
                        color: #7f8c8d;
                    }

                    &__name {
                        max-width: 150px;
                    }
                }
            }

            button {
                display: block;
                margin: 0 auto;
            }
        }

        tbody tr td.ActionCell .dropdown {
            border-radius: 25px;
            background-color: white;
            border-color: black;
            padding: 0.5px 10px;

            .left {
                display: none;
            }

            .ellipsis.horizontal.icon {
                margin-left: 0px;
                margin-right: 0px;
                color: black;
            }
        }
    }

    .Pagination {
        &:after {
            content: "";
            clear: both;
            display: table;
        }

        .ui.pagination.menu {
            float: right;
        }
    }
}
